/*------------------------------------------------------------------

Project        :   Pinlist – Directory, Classifieds and Jobs Multipurpose Bootstrap5 HTML5 Listing Template
Version        :   V.1.0
Create Date    :   02/06/22
Copyright      :   Spruko Technologies Private Limited 
Author         :   SPRUKO™
Author URL     :   https://themeforest.net/user/spruko
Support	       :   support@spruko.com
License        :   http://themeforest.net/licenses/standard

-------------------------------------------------------------------*/

/* ###### Plugins ####### */

@import "variables";
@import "plugins/accordion/accordion";
@import "plugins/calendar/jquery.datetimepicker";
@import "plugins/charts-c3/c3-chart";
@import "plugins/charts-nvd3/nv.d3";
@import "plugins/chat/jquery.convform";
@import "plugins/count-down/flipclock";
@import "plugins/datatable/dataTables.bootstrap";
@import "plugins/datatable/dataTables.bootstrap5";
@import "plugins/datatable/jquery.dataTables";
@import "plugins/date-picker/spectrum";
@import "plugins/dygraph-charts/dygraph";
@import "plugins/fileuploads/dropify";
@import "plugins/fullcalendar/fullcalendar.print";
@import "plugins/fullcalendar/fullcalendar";
@import "plugins/gallery-image-zoom/RV-gallery";
@import "plugins/gallery/gallery";
@import "plugins/Image-Zoom/main";
@import "plugins/ion.rangeSlider/ion.rangeSlider";
@import "plugins/ion.rangeSlider/ion.rangeSlider.skinFlat";
@import "plugins/jquery-countdown/countdown";
@import "plugins/jquery-uislider/jquery-ui";
@import "plugins/jvectormap/jvectormap";
@import "plugins/morris/morris";
@import "plugins/notify/jquery.growl";
@import "plugins/owl-carousel/owl.carousel";
@import "plugins/p-scrollbar/p-scrollbar";
@import "plugins/ratings-2/star-rating-svg";
@import "plugins/prettycheckbox/pretty-checkbox";
@import "plugins/primo-slider/primo-slider";
@import "plugins/select2/select2";
// @import "plugins/pscrollbar/pscrollbar";
@import "plugins/summer-note/summernote1";
@import "plugins/sweet-alert/sweetalert";
@import "plugins/tabs/style";
@import "plugins/time-picker/jquery.timepicker";
@import "plugins/timeline/timeline";
@import "plugins/video/video";
@import "plugins/wysiwyag/richtext";