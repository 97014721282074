.ui-timepicker-wrapper {
	overflow-y: auto;
	max-height: 150px;
	width: 6.5em;
	background: $white;
	border: 1px solid $border;
	-webkit-box-shadow: 0 5px 10px $black-2;
	-moz-box-shadow: 0 5px 10px $black-2;
	box-shadow: 0 5px 10px $black-2;
	outline: none;
	z-index: 10001;
	margin: 0;

	&.ui-timepicker-with-duration {
		width: 13em;

		&.ui-timepicker-step-30,
		&.ui-timepicker-step-60 {
			width: 11em;
		}
	}
}

.ui-timepicker-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ui-timepicker-duration {
	margin-left: 5px;
	color: $white-3;
}

.ui-timepicker-list {
	&:hover .ui-timepicker-duration {
		color: $white-3;
	}

	li {
		padding: 3px 0 3px 5px;
		cursor: pointer;
		white-space: nowrap;
		color: $black;
		list-style: none;
		margin: 0;
	}

	&:hover .ui-timepicker-selected {
		background: $white;
		color: $black;
	}
}

li.ui-timepicker-selected {
	background: $primary;
	color: $white;
}

.ui-timepicker-list {

	li:hover,
	.ui-timepicker-selected:hover {
		background: $primary;
		color: $white;
	}
}

li.ui-timepicker-selected .ui-timepicker-duration {
	color: $white-3;
}

.ui-timepicker-list li {
	&:hover .ui-timepicker-duration {
		color: $white-3;
	}

	&.ui-timepicker-disabled {
		color: $white-3;
		cursor: default;

		&:hover {
			color: $white-3;
			cursor: default;
		}
	}

	&.ui-timepicker-selected.ui-timepicker-disabled {
		color: $white-3;
		cursor: default;
	}

	&.ui-timepicker-disabled:hover,
	&.ui-timepicker-selected.ui-timepicker-disabled {
		background: $background;
	}
}