.morris-hover {
	position: absolute;
	z-index: 10;
  
	&.morris-default-style {
	  border-radius: 10px;
	  padding: 6px;
	  color: $white-9;
	  background: $white-3;
	  border: solid 1px $white-3;
	  font-family: sans-serif;
	  font-size: 12px;
	  text-align: center;
  
	  .morris-hover-row-label {
		font-weight: bold;
		margin: 0.25em 0;
	  }
  
	  .morris-hover-point {
		white-space: nowrap;
		margin: 0.1em 0;
	  }
	}
  }